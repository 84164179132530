import React, { useEffect, useState } from 'react';
import { data_fetch_api_resource, check_url_exists } from "../../utils/http_functions";
import {retrieve_token} from "../../utils/auth";
import { useTheme } from "@material-ui/core/styles";
import { SmartTable } from "../SmartTable";
import { history } from '../../config';
import { i18n } from "../../config";
import { LoadingAnimation } from "../LoadingAnimation";
import Fade from '@material-ui/core/Fade';
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import _ from "lodash";
import { useParams } from "react-router-dom";

const Icon = (props) => {
    const theme = useTheme();
    return (
        <SvgIcon viewBox="0 0 24 24" style={{...props.style, ...{
                strokeWidth: 2,
                stroke: props.color || theme.palette.primary.contrastText,
                fill: 'none',
                strokeLinecap: 'round',
                strokeLinejoin: 'round'
            }}} >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M18 8a3 3 0 0 1 0 6"/>
            <path d="M10 8v11a1 1 0 0 1 -1 1h-1a1 1 0 0 1 -1 -1v-5"/>
            <path
                d="M12 8h0l4.524 -3.77a.9 .9 0 0 1 1.476 .692v12.156a.9 .9 0 0 1 -1.476 .692l-4.524 -3.77h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h8"/>
        </SvgIcon>
    )
}



export const ComunicacionsButton = (props) => {
    const {contract} = props;
    const [working, setWorking] = useState(false);
    useEffect(() => {
        async function check() {
            const result = await check_url_exists(`/contracts/${contract}/communications/`, 2);
            setWorking(result);
        }
        check();
    })

    return (
        <Fade in={working}>
            <Button
                variant="contained"
                color="primary"
                title={i18n.t("common:text.communications_title")}
                onClick={() => history.push({
                    pathname: i18n.t("common:url.contracts_communications", { contractId: contract }),
                    props: { contract: contract }
                  }
                )}
                startIcon={<Icon/>}
                style={props.style}
            >
                {i18n.t("common:text.communications_button")}
            </Button>
        </Fade>
    )
}

const ComunicacionsList = (props) => {
    // API Endpoint v2/contracts/<contract>/communications/
    const { contract } = props;
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const token = retrieve_token();

    useEffect(() => {
        async function fetchData() {
            try {
                const result = await data_fetch_api_resource(token, `contracts/${contract}/communications/`, 2)
                setItems(result.data.items);
            }
            catch (exc) {
                console.log(exc);
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [contract]);

    if (loading) {
        return <LoadingAnimation />
    } else {
        const header = [
            {title: i18n.t("common:text.communications_subject")},
            {title: i18n.t("common:text.communications_date")}
        ]
        if (_.isEmpty(items)) {
            return <div> {i18n.t("common:text.communications_empty")}</div>
        }
        const data = items.map((item) => [
            <div style={{clear: 'both'}}>
                <details>
                    <summary>
                        <span style={{cursor: 'pointer'}}>{item.subject}</span>
                    </summary>
                    <div style={{backgroundColor: '#fff'}} dangerouslySetInnerHTML={{__html: Buffer.from(item.body, 'base64')}}/>
                </details>
            </div>,
            item.date
        ]);
        return (
            <SmartTable header={header} data={data} />
        )
    }
}


const Comunicacions = (props) => {
  let { contractId } = useParams();

  return (
    <div>
      <h1>{i18n.t("common:text.communications_title")} {contractId}</h1>
      <ComunicacionsList contract={contractId}/>
    </div>
  );
}

export default Comunicacions;
